.header-menu-right li,
.header-menu-right ul {
  margin: 0;
  list-style: none;
}

.header-menu__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.menu-categories__item-active
  .menu-categories__title--level-1
  .menu-title:after {
  content: "";
  display: block;
  position: absolute;
  top: 215%;
  height: 4px;
  width: calc(100% + 14px);
  background-color: #005eb8;
  -webkit-transform: translateX(-7px);
  transform: translateX(-7px);
}

.menu-categories__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
}

.menu-categories__level-1 {
  display: block;
}

.menu-categories__level-1:not(:first-child) {
  margin-left: 51px;
}

/* .menu-categories__level-1--important,
.menu-categories__level-1:last-child {
  margin-left: 28px;
} */

.menu-container {
  margin: 0 auto;
  width: auto;
  padding: 0 8px;
}

@media only screen and (min-width: 768px) {
  .menu-container {
    width: auto;
    max-width: calc(969px + 16px * 2);
    padding: 0 16px !important;
  }
}

@media only screen and (min-width: 1024px) {
  .menu-container {
    max-width: calc(1170px + 27px * 2);
    padding: 0 27px !important;
  }
}

@media only screen and (min-width: 1280px) {
  .menu-container {
    max-width: calc(1170px + 55px * 2);
    padding: 0 55px !important;
  }
}

@media only screen and (min-width: 1440px) {
  .menu-container {
    max-width: calc(1200px + 135px * 2);
    padding: 0 135px !important;
  }
}

@media only screen and (min-width: 1600px) {
  .menu-container {
    max-width: calc(1200px + 116px * 2);
    padding: 0 116px !important;
  }
}

@media only screen and (min-width: 1920px) {
  .menu-container {
    max-width: 1200px;
    padding: 0 15px !important;
  }
}

.menu-categories__level-1--important .menu-title {
  padding: 10px 20px;
  background: #005eb8;
  color: #fff !important;
}

.menu-categories__level-1--important .menu-title:after {
  display: none !important;
}

.menu-categories__level-1--important .menu-title:hover {
  background: #004688;
  color: #fff;
}

.menu-categories__level-2 {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #dce1e5;
  padding: 24px 0;
  -webkit-transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  li:hover
  .menu-categories__level-2 {
  visibility: visible;
  opacity: 1;
}

.menu-categories__level-2.sub-columns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-categories__level-2.sub-columns .menu-container {
  width: 100%;
}

.menu-categories__level-2 .sub-columns__column--main {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  -ms-flex: 0 0 33.333333%;
  -webkit-box-flex: 0;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

@media only screen and (min-width: 1280px) {
  .menu-categories__level-2 .sub-columns__column--main {
    max-width: 280px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 280px;
    flex: 0 0 280px;
  }
}

@media only screen and (min-width: 1600px) {
  .menu-categories__level-2 .sub-columns__column--main {
    max-width: 400px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 400px;
    flex: 0 0 400px;
  }
}

.menu-categories__level-2 .sub-columns__columns {
  position: relative;
  width: 100%;
  -ms-flex: 0 0 58.333333%;
  -webkit-box-flex: 0;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  padding: 0 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (min-width: 1280px) {
  .menu-categories__level-2 .sub-columns__columns {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media only screen and (min-width: 1600px) {
  .menu-categories__level-2 .sub-columns__columns {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 1280px) {
  .menu-categories__level-2 .sub-columns__columns.subcolumns_4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 69%;
    flex: 0 0 69%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 1440px) {
  .menu-categories__level-2 .sub-columns__columns.subcolumns_4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 68%;
    flex: 0 0 68%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 1600px) {
  .menu-categories__level-2 .sub-columns__columns.subcolumns_4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 100%;
  }
}

.menu-categories__level-2 .sub-columns__column {
  padding-left: 0;
  -webkit-box-flex: 2;
  -ms-flex: 2;
  flex: 2;
}

.menu-categories__level-2 .sub-columns__title {
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  color: #222;
  margin-bottom: 8px;
}

.menu-categories__level-2 .sub-columns__title:after {
  content: "";
  display: block;
  width: 40px;
  height: 2px;
  background-color: #ed8b00;
  margin: 10px 0 0;
}

.menu-categories__level-2 .sub-columns__description {
  font-size: 14px;
  line-height: 1.71;
  color: #333;
  margin-top: 14px;
}

.menu-categories__level-2 .sub-columns__cta-question {
  color: #222;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 600;
}

.menu-categories__level-2 .sub-columns__cta-button {
  margin-top: 10px;
}

.menu-categories__level-2 .sub-columns__cta-button .btn-default {
  line-height: 20px;
  padding: 10px 0;
  white-space: normal;
  min-width: 130px;
  width: 130px;
  text-transform: uppercase;
  outline: 0;
  text-align: center;
}

.menu-categories__title--level-1,
.menu-categories__title--level-2 {
  margin: 0;
}

.menu-categories__title--level-1 .menu-title {
  color: #222;
  text-transform: uppercase;
  font-size: 14px;
  cursor: pointer;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  position: relative;
  font-weight: 600;
}
.menu-icon {
  padding-top: 3px;
}

.__active .menu-categories__title--level-1 .menu-title,
.menu-categories__title--level-1 .menu-title:hover {
  color: #005eb8;
}

.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  li
  a.active {
  color: #005eb8 !important;
}

.header-area
  .header-menu-wrapper
  .header-menu-flex
  .header-menu-right
  nav
  ul
  li
  a.active
  .menu-title:after {
  content: "";
  display: block;
  position: absolute;
  top: 170%;
  height: 4px;
  width: calc(100% + 14px);
  background-color: #005eb8;
  -webkit-transform: translateX(-7px);
  transform: translateX(-7px);
}

.menu-categories__title--level-1 .menu-title:hover:after {
  content: "";
  display: block;
  position: absolute;
  top: 170%;
  height: 4px;
  width: calc(100% + 14px);
  background-color: #005eb8;
  -webkit-transform: translateX(-7px);
  transform: translateX(-7px);
}

.menu-categories__title--level-2 {
  font-size: 0.83em;
  position: relative;
  z-index: 10;
  padding: 8px 15px 8px 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.menu-categories__title--level-2 a {
  display: inline-block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.menu-categories__title--level-2.active .menu-title {
  color: #005eb8;
  text-decoration: underline;
}

.menu-categories__title--level-2 .menu-title {
  color: #2b2826;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  cursor: pointer;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.menu-categories__title--level-2 .menu-description {
  color: #2b2826;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.6;
  cursor: pointer;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.menu-categories__title--level-2:hover .menu-title {
  color: #005eb8;
}

.menu-categories__level-1 + .menu-categories__level-1--important {
  margin-left: 28px;
}

@media only screen and (min-width: 1280px) {
  body.header-menu-activated:after {
    content: "";
    position: absolute;
    display: block;
    top: 130px;
    left: 0;
    width: 100%;
    height: calc(100% - 125px);
    z-index: 146;
    background: rgba(29, 35, 44, 0.1);
  }
}

.microsite nav.header-menu {
  width: 100%;
}

.microsite nav.header-menu .menu-categories__item-align-right {
  margin-left: auto;
  padding-left: 51px;
}

.microsite
  nav.header-menu
  .menu-categories__item-align-right.menu-categories__level-1--important {
  padding-left: 28px;
}

.microsite .sub-columns__column--main {
  display: none;
}

.header-search,
.header-search-row__form {
  display: -webkit-box;
  display: -ms-flexbox;
}

.microsite .sub-columns__columns {
  margin-left: 15px;
}

.header-search {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-search__toggle {
  overflow: visible;
}

@media only screen and (min-width: 1280px) {
  .header-search__toggle {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #005eb8;
  }
}

.header-search__icon {
  color: #828282;
  font-size: 18px;
  max-height: 25px;
  width: 18px;
  height: 18px;
  vertical-align: middle;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.header-search__icon:before {
  vertical-align: text-bottom;
}

.header-search__icon:hover {
  opacity: 0.7;
}

.header-search-row {
  position: absolute;
  left: 0;
  top: -8px;
  right: 0;
  overflow: hidden;
  background-color: #fff;
  z-index: 10;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  visibility: hidden;
  opacity: 0;
  height: 45px;
}

@media only screen and (max-width: 767px) {
  .header-search-row {
    width: calc(100% - 10px);
    top: 5px;
    left: 5px;
  }
}

@media only screen and (min-width: 768px) {
  .header-search-row {
    left: auto;
    width: calc(100% - 75px);
    top: -10px;
  }
}

@media only screen and (min-width: 1280px) {
  .header-search__icon {
    color: #fff;
  }
  .header-search-row {
    width: calc(100% - 20px);
    top: 0;
  }
}

.header-search-row.show {
  visibility: visible;
  opacity: 1;
}

.header-search-row__form {
  display: flex;
  width: calc(100% - (18px + 10px));
  height: 40px;
}

.header-menu-toggle,
.menu-mobile.opened {
  display: -webkit-box;
  display: -ms-flexbox;
}

.header-search-row__field {
  padding: 0 40px 0 13px;
  font-size: 14px;
  position: relative;
  border: 1px solid #e7e7e7;
  color: #2b2826;
}

.header-search-row__field:focus {
  outline: 0;
  border: 1px solid #c4c4c4;
}

.header-search-row__close {
  position: absolute;
  right: 0;
  top: 11px;
  color: #222;
  height: 18px;
  width: 18px;
  font-size: 18px;
}

.header-menu-toggle {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 25px;
}

.header-menu-toggle .burger {
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.header-menu-toggle .burger span,
.header-menu-toggle .burger:after,
.header-menu-toggle .burger:before {
  background-color: #828282;
}

@media only screen and (min-width: 1280px) {
  .header-menu-toggle .burger span,
  .header-menu-toggle .burger:after,
  .header-menu-toggle .burger:before {
    background-color: #222;
  }
}

.header-menu-toggle .burger:hover {
  opacity: 0.7;
}

.menu-mobile {
  background-color: rgba(29, 35, 44, 0.8);
  display: none;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100001;
  -webkit-animation-name: showMenuMobile;
  animation-name: showMenuMobile;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@media only screen and (min-width: 1280px) {
  .menu-mobile {
    display: none !important;
  }
}

.menu-mobile.opened {
  display: flex;
}

.menu-mobile__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 100%;
  -webkit-animation-name: showMenuListMobile;
  animation-name: showMenuListMobile;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

.menu-mobile__inner {
  height: 100%;
  width: 100%;
  max-width: 276px;
  position: relative;
  background: #fff;
  padding: 5px 0 24px;
  z-index: 100002;
  -webkit-box-shadow: -4px 0 10px 0 rgba(0, 0, 0, 0.6);
  box-shadow: -4px 0 10px 0 rgba(0, 0, 0, 0.6);
}

.menu-mobile__items {
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu-mobile__items__cta {
  bottom: 0;
  position: absolute;
  padding-bottom: 30px;
  padding-left: 24px;
  background-color: #fff;
  z-index: 2;
}

.menu-mobile__items__cta-question {
  color: #222;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 600;
}

.menu-mobile__items__cta-button {
  margin-top: 10px;
}

.menu-mobile__items__cta-button .btn-default {
  line-height: 20px;
  padding: 10px 0;
  white-space: normal;
  min-width: 130px;
  width: 130px;
  text-transform: uppercase;
  outline: 0;
  text-align: center;
}

.menu-mobile__close {
  width: calc(100% - 276px);
}

.menu-mobile__close .btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  margin: 16px 16px 0 auto;
  font-size: 16px;
  color: #fff;
}

.menu-mobile__close .btn .icon-s-close {
  font-weight: 600;
}

.menu-mobile-list__title {
  color: #222;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  height: 44px;
  line-height: normal;
  padding: 15px 24px 14px;
  margin: 0;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: none;
  transition: none;
  position: relative;
  background: 0 0;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
}

.open-sub-items .menu-mobile-list__title {
  color: #005eb8;
}

.menu-mobile-list__title--toggle:after {
  font-family: s-icons;
  font-size: 7px;
  color: #005eb8;
  position: absolute;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  top: calc(50% - 3px);
  left: calc(100% - 36px);
}

.open-sub-items .menu-mobile-list__title--toggle:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.menu-mobile-list__important {
  background: #005eb8;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  padding: 10px 20px;
  font-size: 14px;
  margin: 16px 0 6px 24px;
  display: inline-block;
}

.menu-mobile-sublist {
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
}

.open-sub-items .menu-mobile-sublist {
  display: block;
}

.menu-mobile-sublist__items:active {
  background-color: #d7eeff;
}

.menu-mobile-sublist__category,
.menu-mobile-sublist__title {
  color: #2b2826;
  font-size: 14px;
  line-height: normal;
  text-decoration: none;
  text-transform: capitalize;
  padding: 8px 13px 8px 40px;
  margin: 0;
  min-height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 400;
}

.menu-mobile-sublist__category {
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: #222;
}

.menu-mobile-sublist__category:after {
  content: "";
  display: block;
  width: 40px;
  height: 2px;
  background-color: #ed8b00;
  margin: 10px 0 0;
}

@-webkit-keyframes showMenuMobile {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes showMenuMobile {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes showMenuListMobile {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes showMenuListMobile {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1000;
  padding: 13px 0;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}

.header__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 25px;
}

.header__bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

@media only screen and (min-width: 768px) {
  .header__bar.open-search {
    position: relative;
  }
}

.header__menu {
  display: none;
}

.header__search {
  margin-left: 28px;
}

@media only screen and (min-width: 1600px) {
  .header__search {
    margin-left: 100px;
  }
}

@media only screen and (min-width: 1280px) {
  .home-page .header,
  .home-page .header.header-transparent {
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
  }
  .home-page .header.header-solid {
    background-color: #fff;
    -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  }
  .home-page .header.header-animation {
    -webkit-animation: bg-color 1s;
    animation: bg-color 1s;
    background-color: #fff;
    -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  }
  .header__wrapper {
    height: 45px;
  }
  .header__menu {
    display: block;
  }
  .header__menu-toggle {
    visibility: hidden;
    display: none;
  }
  .microsite .header .header__wrapper {
    height: 104px;
  }
}

@media only screen and (max-width: 767px) {
  body.header-menu-activated {
    overflow: hidden;
  }
  .header .menu-container {
    padding: 0 16px;
  }
}

.microsite .header .header__wrapper {
  display: block;
}

@media only screen and (max-width: 1279px) {
  .microsite .header .header__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.microsite .header .header__wrapper .header-brand-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 26px;
}

@media only screen and (max-width: 1279px) {
  .microsite .header .header__wrapper .header-brand-wrapper {
    margin-bottom: 0;
    position: relative;
  }
}

.microsite .header .header__wrapper .header-brand-wrapper .header__brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
  height: auto;
  overflow: initial;
}

.microsite
  .header
  .header__wrapper
  .header-brand-wrapper
  .header__brand
  .brand-separator {
  height: 100%;
  width: 2px;
  background-color: #d8d8d8;
  margin: 0 14px 0 20px;
}

@media only screen and (max-width: 1279px) {
  .microsite
    .header
    .header__wrapper
    .header-brand-wrapper
    .header__brand
    .brand-separator {
    margin: 0 14px;
  }
}

.microsite
  .header
  .header__wrapper
  .header-brand-wrapper
  .header__brand
  .site-name {
  color: #222;
  font-size: 16px;
  font-weight: 600;
  padding: 0 6px;
}

@media only screen and (max-width: 767px) {
  .microsite
    .header
    .header__wrapper
    .header-brand-wrapper
    .header__brand
    .brand-separator,
  .microsite
    .header
    .header__wrapper
    .header-brand-wrapper
    .header__brand
    .site-name {
    display: none;
  }
}

.microsite
  .header
  .header__wrapper
  .header-brand-wrapper
  .header__brand
  a.site-name:visited {
  color: #222;
}

.microsite
  .header
  .header__wrapper
  .header-brand-wrapper
  .header__brand
  a.site-name:hover {
  color: #005eb8;
}

@media only screen and (min-width: 1280px) {
  .microsite
    .header
    .header__wrapper
    .header-brand-wrapper
    .header__brand
    a.site-name:hover {
    position: relative;
  }
  .microsite
    .header
    .header__wrapper
    .header-brand-wrapper
    .header__brand
    a.site-name:hover:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: -16px;
    height: 4px;
    width: 100%;
    background-color: #005eb8;
  }
}

.microsite
  .header
  .header__wrapper
  .header-brand-wrapper
  .header__brand
  .header-brand__svg {
  height: 25px;
  width: 125px;
}

@media only screen and (min-width: 1280px) {
  .microsite
    .header
    .header__wrapper
    .header-brand-wrapper
    .header__brand
    .header-brand__svg {
    height: 45px;
    width: 215px;
  }
}

.microsite .header .header__wrapper .header-brand-wrapper .header__search {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  position: relative;
  margin-left: 20px;
}

.microsite
  .header
  .header__wrapper
  .header-brand-wrapper
  .header__search
  .header-search__toggle {
  background-color: transparent;
}

.microsite
  .header
  .header__wrapper
  .header-brand-wrapper
  .header__search
  .header-search__icon {
  color: #222;
}

.microsite .header .header__wrapper .header__bar {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@media only screen and (max-width: 1279px) {
  .microsite .header .header__wrapper .header-brand-wrapper .header__search {
    position: initial;
  }
  .microsite
    .header
    .header__wrapper
    .header-brand-wrapper
    .header__search
    .header-search__icon {
    color: #828282;
  }
  .microsite
    .header
    .header__wrapper
    .header-brand-wrapper
    .header__search
    .header-search-row {
    top: -8px;
    left: 0;
    width: 100%;
  }
  .microsite .header .header__wrapper .header__bar {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.microsite .header .header__wrapper .header__bar.open-search {
  position: initial;
}

@-webkit-keyframes bg-color {
  0% {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  100% {
    background-color: #fff;
    -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  }
}

@keyframes bg-color {
  0% {
    background-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  100% {
    background-color: #fff;
    -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  }
}
